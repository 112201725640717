<template>
	<!-- 监理周报 -->
	<div class="mod-config">
		<el-card body-style="padding-bottom: 0;" style="margin-top: -5px;">
			<div class="title">监理周报</div>
			<div class="line"></div>
			<el-row style="margin-bottom: 5px;">
				<el-col :span="pageSpan">
					<el-button v-preventReClick class="btn" type="primary" size="small" @click="addOrUpdateHandle()"
						v-if="showAdd">创建</el-button>
					<div style="color: red;margin-top:7px;margin-bottom: 7px;" v-if="!showAdd">
						本项目您设置的不是当前类型周报，不能创建该类型的周报。
					</div>
				</el-col>
			</el-row>
		</el-card>
		<div>
			<el-card style="margin-top: -3px; height: 590px;" body-style="height:550px; padding: 0;">
				<el-row style="height: 100%; margin-bottom: 10px;">
					<el-col :span="24" style="height: 100%;">
						<div class="box">
							<el-table height="530" :row-class-name="tableRowClassName" class="dataListTable" border
								:data="dataList" header-align="center" style="width: 100%; margin-bottom: 20px;"
								header-cell-class-name="dataListTHeader" :cell-style="{ padding: '0px' }">
								<el-table-column type="index" label="NO." width="60" align="center">
									<template slot-scope="scope">{{ (pageIndex2 - 1) * limit + scope.$index + 1
									}}</template>
								</el-table-column>
								<el-table-column prop="weeklyName" label="周报编号" min-width="200" align="center">
									<template slot-scope="scope">
										<el-button v-preventReClick type="text" size="small"
											@click="viewPdf(scope.row.filePath, scope.row.filePathUri, scope.row.weeklyName)">{{
												scope.row.weeklyName }}</el-button>
									</template>
								</el-table-column>
								<el-table-column label="日期" min-width="160" align="center">
									<template slot-scope="scope">
										{{ scope.row.beginTime }} ~~ {{ scope.row.endTime }}
									</template>
								</el-table-column>
								<el-table-column prop="insDate" label="制作日期" min-width="100" align="center">
								</el-table-column>
								<el-table-column label="确认状态" width="100" align="center">
									<template slot-scope="scope">
										<span v-if="scope.row.refirmFlg === '1'">已确认</span>
										<span v-else>未确认</span>
									</template>
								</el-table-column>
								<el-table-column prop="refirmMan" label="确认人" width="110" align="center">
								</el-table-column>
								<el-table-column prop="refirmTime" label="确认时间" width="110" align="center">
								</el-table-column>
								<el-table-column label="操作" min-width="200" fixed="right"
									v-if="showDelete || showEdit || showCheck || showRowDownload" align="left">
									<template slot-scope="scope">
										<el-button v-preventReClick type="text" size="small" v-if="scope.row.refirmFlg != '1'"
											@click="editHandle1(scope.row.recNo)">修改</el-button>
										<el-button v-preventReClick type="text" size="small"
											@click="createHandle(scope.row.recNo)"
											v-if="scope.row.weeklyStatus === '1' && scope.row.refirmFlg !== '1'">生成</el-button>
										<el-button v-preventReClick type="text" size="small"
											@click="fileUploadHandle(scope.row.recNo)"
											v-if="scope.row.refirmFlg !== '1'">上传</el-button>
										<el-button v-preventReClick type="text" size="small"
											@click="refirmHandle(scope.row.recNo, scope.row.refirmMan, scope.row.refirmTime)"
											v-if="scope.row.showRefirm === '1'">确认</el-button>
										<el-button v-preventReClick type="text" size="small"
											@click="cancelRefirmHandle(scope.row.recNo)"
											v-if="scope.row.refirmFlg === '1'">解除确认</el-button>
										<el-button v-preventReClick type="text" size="small"
											@click="downloadHandle(scope.row.recNo)"
											v-if="showRowDownload">下载</el-button>
										<el-button v-preventReClick type="text" size="small"
											@click="zipDownLoadWeekly(scope.row)"
											v-if="showRowDownload">打包下载</el-button>
										<el-button v-preventReClick type="text" size="small"
											@click="deleteHandle(scope.row.recNo)"
											v-if="showDelete && scope.row.refirmFlg != '1'">删除</el-button>
									</template>
								</el-table-column>
							</el-table>
						</div>
						<el-pagination class="my-pagination" @size-change="sizeChangeHandle"
							@current-change="currentChangeHandle" :current-page="page" :page-sizes="[10, 20, 50, 100]"
							:page-size="limit" :total="totalCount" layout="total, sizes, prev, pager, next, jumper">
						</el-pagination>
					</el-col>
				</el-row>
			</el-card>
		</div>
		<add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
		<weekly-export v-if="exportVisible" ref="weeklyExport" @refreshDataList="getDataList"></weekly-export>
		<audit v-if="auditVisible" ref="audit" @refreshDataList="getDataList"></audit>
		<weekly-file-upload v-if="fileUploadVisible" ref="weeklyFileUpload"
			@refreshDataList="getDataList"></weekly-file-upload>
		<enclosure-add-or-update v-if="enclosureVisible" ref="enclosureAddOrUpdate"
			@refreshDataList="getDataList"></enclosure-add-or-update>
		<project-weekly-report-download v-if="downloadVisible" ref="projectWeeklyReportDownload"
			@refreshDataList="getDataList"></project-weekly-report-download>
		<project-weekly-report-file-upload v-if="reportFileUploadVisible" ref="projectWeeklyReportFileUpload"
			@refreshDataList="getDataList"></project-weekly-report-file-upload>
		<project-weekly-refirm v-if="refirmVisible" ref="projectWeeklyRefirm" @refreshDataList="getDataList"></project-weekly-refirm>
		<view-pdf v-if="viewPdfVisible" ref="viewPdf" @refreshDataList="dataList"></view-pdf>
	</div>
</template>
<script>
import AddOrUpdate from './project-weekly-add-or-update'
import WeeklyExport from './weekly-export'
import EnclosureAddOrUpdate from './enclosure-list'
import $common from "@/utils/common.js"
import audit from './weekly-audit'
import WeeklyFileUpload from "./weekly-file-upload";
import ProjectWeeklyReportDownload from "./project-weekly-report-download.vue"
import ProjectWeeklyReportFileUpload from "./project-weekly-report-file-upload.vue"
import ProjectWeeklyRefirm from "./project_weekly_refirm"
import ViewPdf from "../projectInfo/viewPdf.vue"
export default {
	data() {
		return {
			viewPdfVisible: false,
			showSearch: false,
			showDownload: false,
			showAdd: false,
			showEdit: false,
			showDelete: false,
			showCheck: false,
			action: '',
			showArchive: false,
			showRowDownload: false,
			pageSpan: 12,
			refirmVisible: false,
			addOrUpdateVisible: false,
			fileUploadVisible: false,
			exportVisible: false,
			auditVisible: false,
			enclosureVisible: false,
			reportUploadVisible: false,
			downloadVisible: false,
			reportFileUploadVisible: false,
			form: {},
			limit: 20,
			page: 1,
			totalCount: 0,
			dataList: [],
			tableSelVal: [],
			// 显示序号
			pageIndex2: 1,
		};
	},
	components: {
		ProjectWeeklyReportFileUpload,
		ProjectWeeklyReportDownload,
		WeeklyFileUpload,
		AddOrUpdate,
		audit,
		WeeklyExport,
		EnclosureAddOrUpdate,
		ProjectWeeklyRefirm,
		ViewPdf
	},
	mounted() {
		this.showSearch = $common.isAuth('weekly:search');
		this.showDownload = $common.isAuth('weekly:download');
		this.showAdd = $common.isAuth('weekly:add');
		this.showEdit = $common.isAuth('weekly:edit');
		this.showDelete = $common.isAuth('weekly:delete');
		this.showCheck = $common.isAuth('weekly:check');
		this.showArchive = $common.isAuth('weekly:archive');
		this.showRowDownload = $common.isAuth('weekly:download');
		if (!this.showSearch && !this.showDownload && !this.showAdd) {
			this.pageSpan = 24;
		}
		this.getDataList();
		this.valisShowAddButton();
	},
	methods: {
		valisShowAddButton() {	
			this.$http({
				url: this.$store.state.httpUrl + "/index/infoName/" + $common.getItem("workNo"),
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					let weeklyReportTypeId = data.body.weeklyReportTypeId;
					if(weeklyReportTypeId != '' && weeklyReportTypeId != undefined && weeklyReportTypeId !== 'WEEK02') {
						this.showAdd = false;
					}
				}
			});
		},
		uploadFileHandle(id, weeklyName) {
			this.fileUploadVisible = true
			this.$nextTick(() => {
				this.$refs.weeklyFileUpload.init(id, weeklyName)
			})
		},
		enclosureHandle(id) {
			this.enclosureVisible = true
			this.$nextTick(() => {
				this.$refs.enclosureAddOrUpdate.init(id)
			})
		},
		viewPdf(filePath, fileUri, filename) {
			if(filePath == null || filePath == undefined || filePath == '') {
				this.$message.error("没有可预览的文件！");
				return;
			} 
			// pdf和doc文件，需要pdf在线预览，其他类型文件直接下载
			let suffixType = fileUri.substr(fileUri.lastIndexOf(".") + 1)
			if(suffixType != '' && (suffixType === 'pdf' || suffixType === 'doc' || suffixType === 'docx')) {
				// $common.viewFile(filePath)
				this.viewPdfVisible = true;
				this.$nextTick(() => {
					this.$refs.viewPdf.init(filename, filePath)
				})
			} else {
				filename = this.getSuffix(fileUri, filename)
				this.getBlob(fileUri, (blob) => {
					this.saveAs(blob, filename)
				})
			}
		},
		weeklyReportUpload() {
			this.reportUploadVisible = true;
			this.$nextTick(() => {
				this.$refs.weeklyReportUpload.init()
			})
		},
		fileUploadHandle(id) {
			this.reportFileUploadVisible = true
			this.$nextTick(() => {
				this.$refs.projectWeeklyReportFileUpload.init(id)
			})
		},
		refirmHandle(id, refirmMan, refrimTime) {
			this.refirmVisible = true
			this.$nextTick(() => {
				this.$refs.projectWeeklyRefirm.init(id, refirmMan, refrimTime)
			})
		},
		cancelRefirmHandle(id) {
			this.$confirm(`确定对选中的数据进行解除确认操作?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http({
					url: this.$store.state.httpUrl + "/business/projectweeklyreportinfo/cancelProjectWeeklyRefirm/" + id,
					method: "post",
					data: {},
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getDataList()
							}
						})
					}
				});
			})
		},
		downloadHandle(id) {
			this.downloadVisible = true;
			this.$nextTick(() => {
				this.$refs.projectWeeklyReportDownload.init(id)
			})
		},
		zipDownLoadWeekly(item) {
			var fileName = "监理周报（" + item.weeklyName + "）.zip";
			this.$http({
				url: this.$store.state.httpUrl + "/business/projectweeklyreportinfo/zipDownLoadProjectWeekly?recNo=" + item.recNo,
				method: "POST",
				data: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.$message({
						message: '操作成功',
						type: 'success',
						duration: 1500,
						onClose: () => {
							this.global.downLoadFile(data.body, fileName);
						}
					})
				}
			});
		},
		//重置
		resetFormData() {
			this.pageIndex = 1;
			this.getDataList();
		},
		getDataList() {
			if (this.showSearch) {
				this.$http({
					url: this.$store.state.httpUrl + "/business/projectweeklyreportinfo/list",
					method: 'get',
					params: this.$http.adornParams({
						limit: this.limit,
						page: this.page,
						workNo: $common.getItem("workNo")
					})
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.dataList = data.body.records;
						this.totalCount = data.body.totalCount;
						this.pageIndex2 = this.page
					} else {
						this.dataList = []
						this.totalCount = 0
						this.pageIndex2 = 1
					}
				})
			}
		},
		createHandle(id) {
			this.$confirm(`确定对选中的数据进行生成周报吗?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http({
					url: this.$store.state.httpUrl + "/business/projectweeklyreportinfo/downloadProjectWeek/" + id,
					method: "POST",
					data: {},
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getDataList()
							}
						})
					}
				});
			})
		},
		//删除
		deleteHandle(id) {
			this.$confirm(`确定对选中的数据进行删除操作?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http({
					url: this.$store.state.httpUrl + "/business/projectweeklyreportinfo/delete/" + id,
					method: "post",
					data: {},
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getDataList()
							}
						})
					} 
				});
			})
		},
		siteContentViewHeight() {
			var height = this.$store.state.common.documentClientHeight - 190;
			return { height: height + 'px' }
		},
		handleSelectionChange(val) {
			this.tableSelVal = val;
		},
		tableRowClassName({ row, rowIndex }) {
			return $common.tableRowClassName({ row, rowIndex });
		},
		archiveFile(id) {
			this.$confirm(`确定对选中的发文进行归档操作?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http({
					url: this.$store.state.httpUrl + "/business/weeklyreportinfo/archiveFile/" + id,
					method: "get",
					data: id,
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getDataList()
							}
						})
					} else {
						this.$message.error("归档设置失败")
					}
				});
			});
		},
		removeArchiveFile(id) {
			this.$confirm(`确定对选中的发文进行取消归档操作?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http({
					url: this.$store.state.httpUrl + "/business/weeklyreportinfo/removeArchiveFile/" + id,
					method: "get",
					data: id,
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getDataList()
							}
						})
					} else {
						this.$message.error("取消归档失败")
					}
				});
			});
		},
		// 每页数
		sizeChangeHandle(val) {
			this.limit = val
			this.page = 1
			this.getDataList()
		},
		// 当前页
		currentChangeHandle(val) {
			this.page = val
			this.getDataList()
		},
		addOrUpdateHandle(id) {
			this.addOrUpdateVisible = true;
			this.$nextTick(() => {
				this.$refs.addOrUpdate.init(id);
			})
		},
		editHandle(id, reportKind, weeklyStatus) {
			if (reportKind === '2') {
				this.$router.push({ name: 'weeklyDeviceExportInfo', query: { recNo: id } });
			} else {
				if (weeklyStatus === "0") {
					this.refreshEnclosureFiles(id)
				} else {
					this.refreshAllDataList(id)
				}
			}
		},
		editHandle1(id) {
			this.$router.push({ name: 'projectWeeklyExportInfo', query: { recNo: id } });
		},
		exportHandle() {
			this.exportVisible = true
			this.$nextTick(() => {
				this.$refs.weeklyExport.init()
			})
		},
		refreshEnclosureFiles(id) {
			let _url = "/business/weeklyreportinfo/refreshEnclosureFiles/" + id;
			this.$http({
				url: this.$store.state.httpUrl + _url,
				method: "post",
				data: []
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					// 跳转
					this.$router.push({ name: 'weeklyExportInfo', query: { recNo: id } });
				}
			});
		},
		refreshAllDataList(id) {
			let _url = "/business/weeklyreportinfo/refreshAllDataList/" + id;
			this.$http({
				url: this.$store.state.httpUrl + _url,
				method: "post",
				data: []
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					// 跳转
					this.$router.push({ name: 'weeklyExportInfo', query: { recNo: id } });
				}
			});
		}
	}
};
</script>
<style lang="scss" scoped="scoped">
.selItemText {
	font-size: 14px;
	color: #1c1c1c;
	display: inline-block;
	width: 80px;
}

.my-pagination {
	margin-right: 20px !important;
}

// 设置表头样式
::v-deep(.el-table th.el-table__cell) {
	// background-color: #d3e2f4 !important;
	font-size: 15px;
	padding: 2px 0;
}
</style>
