<template>
	<el-dialog :title="'上传文件'" :close-on-click-modal="false" :visible.sync="subVisible" width="700px">
		<el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="100px">
			<el-row>
				<el-col :span="24">
					<el-form-item label="周报编号" prop="weeklyName">
						{{ dataForm.weeklyName }}
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="6" style="margin-left: 20px;">
					<el-upload ref="uploadScanFile" :action="action" :http-request="uploadScanFile" :data="{ type: 1 }"
						aria-disabled="!dataForm.fileName">
						<el-button v-preventReClick class="defaultPlainBtn" plain icon="el-icon-upload">周报上传</el-button>
					</el-upload>
				</el-col>
				<el-col :span="17" v-if="dataForm.fileName">
					<div class="addFileBox">
						<span class="tmp" id="tmp" title="">{{ dataForm.fileName }}</span>
						<el-button v-preventReClick type="primary" size="small" @click="deleteReportFile()">删除</el-button>
						<el-button v-preventReClick type="success" size="small"
							@click="viewReportFile(dataForm.fileUri, dataForm.fileName)"
							style="margin-left: 20px;">查看</el-button>
					</div>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="24" style="margin-left: 20px;">
					<el-upload ref="uploadScanEncolsureFile" :action="action" :http-request="uploadScanFile"
						:data="{ type: 2 }">
						<el-button v-preventReClick class="defaultPlainBtn" plain
							icon="el-icon-upload">周报附件上传（最多能上传10个附件）</el-button>
					</el-upload>
				</el-col>
			</el-row>
			<el-row v-for="(enclosureFile, index) in dataForm.enclosureFileList" style="margin-top: 10px;">
				<el-col :span="24" v-if="dataForm.enclosureFileList">
					<span style="margin-left: 20px;margin-right: 20px;">{{ index + 1 }}</span>
					<el-input v-model="enclosureFile.fileName" placeholder="请填写名称"
						style="width: 300px;margin-left: 10px;"></el-input>
					<el-button v-preventReClick type="primary" size="small" @click="deleteEncolsureFile(index)"
						style="margin-left: 70px;">删除</el-button>
					<el-button v-preventReClick type="success" size="small"
						@click="viewReportFile(enclosureFile.fileUri, enclosureFile.fileName)"
						style="margin-left: 20px;">查看</el-button>
				</el-col>
			</el-row>
		</el-form>
		<el-divider></el-divider>
		<el-row class="formBtnGroup">
			<el-button v-preventReClick class="btn" type="primary" size="small" @click="dataFormSubmit">保
				存</el-button>
		</el-row>
	</el-dialog>
</template>

<script>
import addImg from "@/assets/images/addImg.png"
import $common from "@/utils/common.js"
export default {
	name: "weekly-file-upload",
	data() {
		return {
			addImg: addImg,
			id: "",
			subVisible: true,
			form: {},
			action: "",
			fileType: "",
			dataForm: {
				recNo: '',
				weeklyName: '',
				fileName: "",
				filePath: "",
				enclosureFileList: [],
			},
			dataRule: {
				weeklyName: [
					{ required: true, message: "周报名称不能为空！", trigger: "blur" }
				],
			}
		};
	},
	components: {

	},
	activated() {

	},
	methods: {
		init(id) {
			this.dataForm = {
				recNo: '',
				weeklyName: '',
				fileName: "",
				filePath: "",
				fileUri: '',
				enclosureFileList: []
			}
			this.dataForm.recNo = id
			this.getWeeklyEnclosureFileList();
			this.$nextTick(() => {
				this.subVisible = true;
			})
			this.$refs['dataForm'].resetFields();

		},
		deleteReportFile() {
			this.dataForm.filePath = '';
			this.dataForm.fileName = '';
		},
		viewReportFile(url, filename) {
			// filename = this.getSuffix(url, filename)
			this.getBlob(url, (blob) => {
				this.saveAs(blob, filename)
			})
		},
		getSuffix(url, filename) {
			let lastIndex = url.lastIndexOf(".");
			return filename + url.substr(lastIndex)
		},
		getBlob(url, cb) {
			var xhr = new XMLHttpRequest()
			xhr.open('GET', url, true)
			xhr.responseType = 'blob'
			xhr.onload = function () {
				if (xhr.status === 200) {
					cb(xhr.response)
				}
			}
			xhr.send()
		},
		saveAs(blob, filename) {
			if (window.navigator.msSaveOrOpenBlob) {
				navigator.msSaveBlob(blob, filename)
			}
			else {
				var link = document.createElement('a')
				var body = document.querySelector('body')

				link.href = window.URL.createObjectURL(blob)
				link.download = filename

				link.style.display = 'none'
				body.appendChild(link)

				link.click()
				body.removeChild(link)
				window.URL.revokeObjectURL(link.href)
			}
		},
		deleteEncolsureFile(index) {
			this.dataForm.enclosureFileList.splice(index, 1);
		},
		viewEncolsureFile(index) {
			window.open(this.dataForm.enclosureFileList[index].fileUri);
		},
		getWeeklyEnclosureFileList() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/projectweeklyreportinfo/getWeeklyEnclosureFileList/" + this.dataForm.recNo,
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.dataForm.recNo = data.body.recNo;
					this.dataForm.weeklyName = data.body.weeklyName;
					this.dataForm.filePath = data.body.filePath;
					this.dataForm.fileName = data.body.fileName;
					this.dataForm.fileUri = data.body.fileUri;
					this.dataForm.enclosureFileList = data.body.enclosureFileList;
				}
			});
		},
		uploadScanFile(param) {
			let type = param.data.type;
			const formData = new FormData();
			formData.append('file', param.file);
			if (type === 1) {
				this.$refs.uploadScanFile.clearFiles();
			} else {
				this.$refs.uploadScanEncolsureFile.clearFiles();
			}
			this.$http.post(
				this.$store.state.httpUrl + "/file/upload/" + $common.getItem("workNo") + "/95/0",
				formData,
				{
					headers: { 'Content-Type': 'multipart/form-data' }
				},
			).then(res => {
				res = res.data
				if (res.resultCode === 200) {
					if (type === 1) {
						this.dataForm.fileName = res.body.fileName;
						this.dataForm.filePath = res.body.fileSavePath;
						this.dataForm.fileUri = res.body.fileUri
					} else {
						if (this.dataForm.enclosureFileList.length >= 10) {
							this.$message.error('最多只能上传10个附件！');
							return;
						}
						// 判断文件名称是否存在
						let exist = this.checkFileNameExist(res.body.fileName);
						if (exist) {
							this.$message.error("文件名称已存在");
							return;
						}
						let enclosureFile = {
							fileName: res.body.fileName,
							filePath: res.body.fileSavePath,
							fileUri: res.body.fileUri,
                            weeklyRecNo: this.dataForm.recNo,
                            recNo: '',
                            dataType: '090000',
                            souceType: '',
                            sourceId: ''
						}
						this.dataForm.enclosureFileList.push(enclosureFile);
					}
				} else {
					this.$message.info('文件上传失败')
				}
			})
		},
		checkFileNameExist(fileName) {
			let enclosureFileList = this.dataForm.enclosureFileList;
			if (enclosureFileList) {
				for (var i = 0; i < enclosureFileList.length; i++) {
					if (enclosureFileList[i].fileName === fileName) {
						return true;
					}
				}
			}
			return false;
		},
		dataFormSubmit() {
			this.$refs["dataForm"].validate(valid => {
				if (valid) {
					if (!this.dataForm.filePath || !this.dataForm.fileName) {
						this.$message.error("未上传周报正文");
						return;
					}
					let _url = "/business/projectweeklyreportinfo/updateReportFiles";
					this.$http({
						url: this.$store.state.httpUrl + _url,
						method: "post",
						data: this.$http.adornData({
							recNo: this.dataForm.recNo,
							workNo: $common.getItem("workNo"),
							weeklyName: this.dataForm.weeklyName,
							filePath: this.dataForm.filePath,
							fileName: this.dataForm.fileName,
							enclosureFileList: this.dataForm.enclosureFileList,
						})
					}).then(({ data }) => {
						if (data && data.resultCode === 200) {
							this.$message({
								message: "操作成功",
								type: "success",
								duration: 1500,
								onClose: () => {
									this.subVisible = false;
									this.$emit("refreshDataList");
								}
							});
						} else {
							this.$message.error(data.msg);
						}
					});
				}
			});
		}
	}
};
</script>

<style lang="scss" scoped="scoped">
.selItemInput.descAreaLg {
	width: 1030px !important;
}

.addImgBox {
	width: 136px;
	height: 136px;
	display: inline-block;
	vertical-align: middle;
	margin-right: 10px;
	position: relative;
}

.el-divider {
	margin: 60px 0;
	background: 0 0;
	border-top: 1px solid #E6EBF5;
}

.tmp {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	width: 248px;
	display: inline-block;
	color: #00428e;
	margin-top: 13px;
}
</style>
